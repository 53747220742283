import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";

const TrainerDashboard = () => {
  const totalCourses = 4;
  const enrollments = 44;
  const courseRating = 4.3;

  return (
    <Box marginTop={10}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h3">
                My Courses
              </Typography>
              <Typography variant="body1" component="p">
                {totalCourses}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h3">
                Enrollments
              </Typography>
              <Typography variant="body1" component="p">
                {enrollments}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h3">
                Courses Rating
              </Typography>
              <Typography variant="body1" component="p">
                {courseRating}/5
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TrainerDashboard;
