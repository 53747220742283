import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import PrivateRoute from "./component/PrivateRouter";
import { getCurrentUser, getUserRole, login, logout } from "./services/auth";
import Dashboard from "./pages/Dashboard";
import LoginForm from "./pages/LoginForm";
import SignupForm from "./pages/SignupForm";

import { Route, Routes, useNavigate } from "react-router-dom";

// ...

import { useState, useEffect } from "react";
import Protected from "./component/Protected";
import AddCourse from "./pages/Trainer/AddCourse";
import {
  getTrainerCourses,
  getStudentCourses,
  getUserMediaFiles,
  getSettings,
  saveSettings,
  addStudent,
} from "./services/model";
import useLocalStorage from "./services/useLocalStorage";
import TrainerCourses from "./pages/Trainer/TrainerCourses";
import EditCourse from "./pages/Trainer/EditCourse";
import StudentCourses from "./pages/Students/StudentCourses";
import EnrolledOutline from "./pages/Students/EnrolledOutline";
import EnrolledOutlinePreview from "./pages/Trainer/EnrolledOutlinePreview";
import { Container } from "react-bootstrap";
import NavigationBar from "./component/NavBar";
import { add_type_in_media } from "./services/helper";
import CourseEnrollments from "./pages/Trainer/CourseEnrollments";
import EnrollmentChatByTrainer from "./pages/Trainer/EnrollmentChat";
import TrainerDashboard from "./pages/Trainer/TrainerDashboard";
import { Backdrop, CircularProgress } from "@mui/material";
import QuickSignup from "./pages/QuickSignup";
// import AdminSettings from "./pages/Settings/AdminSettings";
// import UserSettings from "./pages/Settings/UserSettings";

let user = getCurrentUser();
let user_role = getUserRole();
console.log(user_role);

function App() {
  const navigate = useNavigate();
  const [User, setUser] = useState(user);
  const [UserRole, setUserRole] = useState(user_role);
  const [Courses, setCourses] = useState([]);
  const [MediaFiles, setMediaFiles] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [Settings, setSettings] = useLocalStorage("nowa_settings", {});

  useEffect(() => {
    const loadData = async () => {
      setIsWorking(true);
      let courses = [];
      if (user_role === "trainer" || user_role === "admin") {
        const { data } = await getTrainerCourses();
        courses = data;
        let { data: media_files } = await getUserMediaFiles();
        // console.log(media_files);
        media_files = add_type_in_media(media_files);
        setMediaFiles(media_files);
      } else if (user_role === "student") {
        const { data } = await getStudentCourses();
        courses = data;
      }
      setIsWorking(false);
      setCourses(courses);

      const { data: settings } = await getSettings();
      const { global_settings, user_settings } = settings;
      const settings_merged = {
        ...global_settings,
        ...user_settings,
      };
      setSettings(settings_merged);
    };

    user && loadData();
    // setUser(User);
  }, [setSettings]);

  const handleLogin = async (username, password) => {
    const user_info = { username, password };
    try {
      const { user } = await login(user_info);
      const user_role = getUserRole(user);
      setUser(user);
      setUserRole(user_role);
      const redirect =
        user_role === "student" ? "student-courses" : "trainer-courses";
      // console.log(user_role, redirect);
      // navigate(`/${redirect}`);
      window.location = `/${redirect}`;
    } catch (ex) {
      toast.error("Error while logging in: " + ex);
    }
  };

  const handleLogout = () => {
    logout();
    setUser(null);
  };

  const handleNewCourse = (course) => {
    const courses = [...Courses, course];
    setCourses(courses);
  };

  const handleCourseUpdate = (course) => {
    let courses = [...Courses];
    let found = courses.find((c) => c.id === course.id);
    const index = courses.indexOf(found);
    courses[index] = course;
    setCourses(courses);
  };

  const handleSettingsSave = async (settings) => {
    const { data: response } = await saveSettings(settings);
    toast.info("Settings are saved");
    setSettings(settings);
  };

  return (
    <Container fluid>
      {User && (
        <NavigationBar
          onLogout={handleLogout}
          User={User}
          UserRole={UserRole}
        />
      )}

      <Routes>
        <Route
          path="/"
          element={
            <Protected User={User}>
              <TrainerDashboard onLogout={handleLogout} />
            </Protected>
          }
        />
        <Route
          path="/add-course"
          element={
            <Protected User={User}>
              <AddCourse
                onLogout={handleLogout}
                afterCourseUpdate={handleNewCourse}
                MediaFiles={MediaFiles}
              />
            </Protected>
          }
        />
        <Route
          path="/edit/:id"
          element={
            <Protected User={User}>
              <EditCourse
                onLogout={handleLogout}
                all_courses={Courses}
                onCourseUpdate={handleCourseUpdate}
                MediaFiles={MediaFiles}
              />
            </Protected>
          }
        />
        <Route
          path="/enrollments/:id"
          element={
            <Protected User={User}>
              <CourseEnrollments />
            </Protected>
          }
        />
        <Route
          path="/enrollments/:id/chat"
          element={
            <Protected User={User}>
              <EnrollmentChatByTrainer />
            </Protected>
          }
        />
        <Route
          path="/trainer-courses"
          element={
            <Protected User={User}>
              <TrainerCourses onLogout={handleLogout} all_courses={Courses} />
            </Protected>
          }
        />
        <Route
          path="/student-courses"
          element={
            <Protected User={User}>
              <StudentCourses onLogout={handleLogout} all_courses={Courses} />
            </Protected>
          }
        />
        <Route
          path="/outline/:id"
          element={
            <Protected User={User}>
              <EnrolledOutline onLogout={handleLogout} all_courses={Courses} />
            </Protected>
          }
        />
        <Route
          path="/outline/preview/:id"
          element={
            <Protected User={User}>
              <EnrolledOutlinePreview
                onLogout={handleLogout}
                all_courses={Courses}
              />
            </Protected>
          }
        />
        {/* <Route
          path="/admin-settings"
          element={
            <Protected User={User}>
              <AdminSettings
                admin_settings={Settings}
                onSettingsSave={handleSettingsSave}
                UserRole={UserRole}
              />
            </Protected>
          }
        />
        <Route
          path="/user-settings"
          element={
            <Protected User={User}>
              <UserSettings
                admin_settings={Settings}
                onSettingsSave={handleSettingsSave}
                UserRole={UserRole}
              />
            </Protected>
          }
        /> */}
        <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
        <Route path="/signup" element={<SignupForm onLogin={handleLogin} />} />
        <Route path="/register" element={<QuickSignup />} />
      </Routes>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWorking}
        onClick={() => setIsWorking(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default App;
