import React from "react";
import { Container } from "react-bootstrap";
import CourseOutline from "../../component/CourseForm";

export default function AddCourse({ afterCourseUpdate, MediaFiles }) {
  return (
    <Container>
      <CourseOutline
        afterCourseUpdate={afterCourseUpdate}
        MediaFiles={MediaFiles}
      />
    </Container>
  );
}
