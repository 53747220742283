import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export function PageHeader({ Title, ButtonLabel, ButtonPath }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <h2>{Title}</h2>

      {ButtonLabel && (
        <Link to={ButtonPath}>
          <Button variant="primary">{ButtonLabel}</Button>
        </Link>
      )}
    </div>
  );
}
