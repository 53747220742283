import { useEffect, useState } from "react";
import { Table, Button, Container, Badge } from "react-bootstrap";
import { Link, useFetcher } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faList,
  faPlay,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { PageHeader } from "../../component/PageHeader";

function TrainerCourses({ all_courses }) {
  const [Courses, setCourses] = useState([...all_courses]);

  useEffect(() => {
    setCourses(all_courses);
  }, [all_courses]);

  const handleDelete = (id) => {
    // Delete the course with the given ID from the API and update the state
  };

  const courseTitle = (course) => {
    let class_name = "";
    let label = "";

    if (course.status === "private") {
      class_name = "secondary";
      label = "Private";
    } else if (course.status === "draft") {
      class_name = "primary";
      label = "Draft";
    } else if (course.status === "publish") {
      class_name = "success";
      label = "Published";
    }

    return (
      <>
        {`${course.title} by ${course.author_name}`}
        <Badge className="m-1" bg={class_name} text="uppercase">
          {label}
        </Badge>
      </>
    );
  };

  const renderCourseAttributes = (course) => {
    return `${course.language.toUpperCase()} - ${course.skill_level.toUpperCase()} - ${
      course.total_videos
    } Videos (${course.video_duration} Sec) - ${
      course.total_sections
    } Sections`;
  };

  return (
    <Container fluid>
      <PageHeader
        Title={"Courses"}
        ButtonLabel="Add Course"
        ButtonPath="/add-course"
      />

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Code</th>
            <th>Title</th>
            <th>Edit</th>
            <th>Attributes</th>
            <th>Preview</th>
            <th>Enrollments</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {Courses.map((course) => (
            <tr key={course.id}>
              <td>{course.course_code}</td>
              <td>{courseTitle(course)}</td>
              <td>
                <Link to={`/edit/${course.id}`}>
                  <Button variant="primary">
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Link>
              </td>
              <td>{renderCourseAttributes(course)}</td>
              <td>
                <Link to={`/outline/preview/${course.id}`}>
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faPlay} size="2x" />
                  </span>
                </Link>
              </td>
              <td>
                <Link to={`/enrollments/${course.id}`}>
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faList} size="2x" />
                  </span>
                </Link>
              </td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(course.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default TrainerCourses;
