import React, { useEffect, useState } from "react";
import { Container, ListGroup, Form, Row, Col } from "react-bootstrap";
import {
  Tabs,
  Button,
  Tab,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Box,
  Divider,
  ListItemText,
  Badge,
} from "@mui/material/";

import { useParams } from "react-router-dom";
import data from "../../services/data.json";
import { VideoPlayerContent } from "../../component/VideoPlayerContent";
import {
  setActiveSubtitle,
  setCompletedSubtitle,
  setCourseRating,
} from "../../services/model";
import { getUserID, getUserRole } from "../../services/auth";
import { toast } from "react-toastify";
import EnrollmentSupport from "../../support/Index";

const { video_cdn } = data;
const user_id = getUserID();
const user_role = getUserRole();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const EnrolledOutline = ({ all_courses, maxChar = 300 }) => {
  const { id } = useParams();
  const [Course, setCourse] = useState({});
  const [CourseData, setCourseData] = useState({});
  const [CourseOutline, setCourseOutline] = useState({});
  const [selectedSubtitle, setSelectedSubtitle] = useState({});
  const [value, setValue] = React.useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [rating, setRating] = useState(0);
  const [RatingText, setRatingText] = useState("");

  useEffect(() => {
    const found = all_courses.find((course) => course.id === Number(id));

    if (found) {
      setCourse(found);
      setCourseData(found.course_data);
      // set rating
      const { rating_stars, rating_text } = found.ratings;
      setRatingText(rating_text);
      setRating(rating_stars);

      // console.log(found.course_data);
      setCompletedOutlineSubtitles(found.completed_subtitles);
      // setActiveSubtitle(found.active_subtitle);
      const outline = found.course_data.outline;
      setCourseOutline(outline);
      const firstSubtitle = Object.keys(outline)[0];

      let selected_subtitle = outline[firstSubtitle].subtitles[0];
      if (found.active_subtitle) {
        // setting the currentSubtitle (active)
        const subtitles = Object.values(outline).flatMap(
          (section) => section.subtitles
        );
        selected_subtitle = subtitles.find(
          (subtitle) => subtitle.id === found.active_subtitle
        );
      }
      setSelectedSubtitle(selected_subtitle);
    }
  }, [all_courses, id]);

  const showContent = () => {
    return isExpanded
      ? CourseData.content
      : CourseData.content.substring(0, maxChar);
  };

  const handleSubmitRating = async (e) => {
    e.preventDefault();

    if (!rating || !RatingText) {
      toast.error("Please provide a rating and review text.");
      return;
    }

    const rating_stars = parseInt(rating);
    const rating_data = {
      rating_stars,
      rating_text: RatingText,
      student_id: user_id,
      enrollment_id: id,
    };
    try {
      const { data: enrollment } = await setCourseRating(rating_data);
      setCourse(enrollment);
      toast.success("Thanks for rating");
    } catch (e) {
      // console.log(e);
      toast.error("Error creating course" + e);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getLanguage = (lang) => {
    const Languages = data.languages;
    return Languages.find((l) => l.value === lang)["label"];
  };

  const getLevel = (level) => {
    const Skills = data.skill_level;
    return Skills.find((l) => l.value === level)["label"];
  };

  const [CompletedOutlineSubtitles, setCompletedOutlineSubtitles] = useState(
    []
  );

  const handleSubtitleClick = async (currSubtitle) => {
    setSelectedSubtitle(currSubtitle);
    // if contentType is not video then it should marked as completed
    if (
      currSubtitle.contentType !== "Video" &&
      !CompletedOutlineSubtitles.includes(currSubtitle.id)
    ) {
      const { data: course } = await setCompletedSubtitle(
        Course.id,
        currSubtitle.id
      );
      setCourse(course);
      setCompletedOutlineSubtitles(course.completed_subtitles);
    }
    await setActiveSubtitle(Course.id, currSubtitle.id);
  };

  const handleSubtitleCompleted = async () => {
    // if already completed, return
    if (CompletedOutlineSubtitles.includes(selectedSubtitle.id)) return;

    const { data: course } = await setCompletedSubtitle(
      Course.id,
      selectedSubtitle.id
    );
    setCompletedOutlineSubtitles(course.completed_subtitles);
    setCourse(course);
  };

  const getAverageRatingText = () => {
    const { average_rating, total_ratings } = CourseData.course_rating;
    return `Rating: ${average_rating} (${total_ratings})`;
  };

  const hasNewMessage = () => {
    const unread = Number(Course.unread_student);
    if (!unread || unread < 1) return false;
    return unread;
  };

  function getVideoDuration(duration) {
    var hours = Math.floor(duration / 3600);
    var minutes = Math.floor((duration % 3600) / 60);
    var seconds = Math.floor(duration % 60);

    var formattedMinutes = ("0" + minutes).slice(-2);
    var formattedSeconds = ("0" + seconds).slice(-2);

    if (hours === 0) {
      return formattedMinutes + ":" + formattedSeconds;
    }

    var formattedHours = ("0" + hours).slice(-2);

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  }

  return (
    <Container fluid>
      <div className="enrolled-outline">
        <div className="enrolled-outline__outline">
          {Object.entries(CourseOutline).map(([sectionId, sectionData]) => (
            <div key={sectionId} className="mb-2">
              <ListGroup as="ul">
                <ListGroup.Item as="li" variant="primary">
                  {sectionData.title}
                </ListGroup.Item>
                {sectionData.subtitles.map((subtitle) => (
                  <ListGroup.Item
                    key={subtitle.id}
                    action
                    active={selectedSubtitle.id === subtitle.id}
                    onClick={() => handleSubtitleClick(subtitle)}
                    style={{
                      backgroundColor: CompletedOutlineSubtitles.includes(
                        subtitle.id
                      )
                        ? "green"
                        : "inherit",
                      color: CompletedOutlineSubtitles.includes(subtitle.id)
                        ? "white"
                        : "inherit",
                    }}
                  >
                    {subtitle.text}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          ))}
        </div>

        <div className="enrolled-outline__content">
          {selectedSubtitle && (
            <>
              {selectedSubtitle.contentType === "Video" ? (
                <VideoPlayerContent
                  mediaFile={selectedSubtitle.mediaFile}
                  video_cdn={video_cdn}
                  onEnded={handleSubtitleCompleted}
                />
              ) : (
                <iframe
                  src={`${video_cdn}/${selectedSubtitle.mediaFile}`}
                  title={selectedSubtitle.text}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                />
              )}
            </>
          )}
          <Box sx={{ width: "100%" }} className="courseInfoTabs">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Course Details"
                sx={{ marginTop: "20px" }}
              >
                <Tab label="Overview" sx={{ fontSize: 18 }} />
                <Tab label="Links" sx={{ fontSize: 18 }} />
                <Tab
                  sx={{ fontSize: 18 }}
                  label={
                    hasNewMessage() ? (
                      <Badge badgeContent={hasNewMessage()} color="primary">
                        Messages
                      </Badge>
                    ) : (
                      "Messages"
                    )
                  }
                />

                <Tab label="Review" sx={{ fontSize: 18 }} />
              </Tabs>
            </Box>
            {CourseData.content && (
              <TabPanel value={value} index={0}>
                <h3>{Course.title}</h3>
                <Divider sx={{ color: "black", mt: 3 }} />
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "20%" }}
                        >
                          By the numbers
                        </TableCell>
                        <TableCell align="left" sx={{ width: "40%" }}>
                          <ListItemText
                            primary={`Skill Level: ${getLevel(
                              CourseData.skill_level
                            )}`}
                          />
                          {/* <ListItemText primary="Students: 2023" /> */}
                          <ListItemText
                            primary={`Languages: ${getLanguage(
                              CourseData.language
                            )}`}
                          />
                          <ListItemText primary="Caption: Yes" />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "40%", fontSize: 18 }}
                        >
                          <ListItemText
                            primary={`Lectures: ${CourseData.total_sections}`}
                          />
                          <ListItemText
                            primary={`Videos: ${
                              CourseData.total_videos
                            } (${getVideoDuration(CourseData.video_duration)})`}
                          />
                          <ListItemText primary={`${getAverageRatingText()}`} />
                        </TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Description
                        </TableCell>
                        <TableCell align="left" colSpan={2}>
                          <Typography
                            variant="body1"
                            sx={{ textAlign: "left" }}
                            dangerouslySetInnerHTML={{
                              __html: showContent(),
                            }}
                          ></Typography>
                          {CourseData.content.length > maxChar && (
                            <Button color="primary" onClick={toggleExpand}>
                              {isExpanded ? "Show Less" : "Show More"}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            )}

            <TabPanel value={value} index={1}>
              <Typography
                variant="body1"
                sx={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{
                  __html: CourseData.course_links,
                }}
              ></Typography>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <EnrollmentSupport EnrollmentID={id} UserRole={user_role} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Form onSubmit={handleSubmitRating}>
                <Form.Group controlId="ratingInput">
                  <Form.Label style={{ fontsize: 20 }}>
                    Enter a feedback:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={RatingText}
                    onChange={(e) => setRatingText(e.target.value)}
                    placeholder="Type here..."
                  />
                </Form.Group>
                <Row>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <Col key={value} xs="auto" onClick={() => setRating(value)}>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "30px",
                          color: value <= rating ? "gold" : "gray",
                        }}
                      >
                        {value <= rating ? "★" : "☆"}
                      </span>
                    </Col>
                  ))}
                </Row>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </Form>
            </TabPanel>
          </Box>
        </div>
      </div>
    </Container>
  );
};

export default EnrolledOutline;
