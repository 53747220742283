import { Box } from "@mui/material";
import { getUserRole } from "../../services/auth";
import EnrollmentSupport from "../../support/Index";
import { useParams } from "react-router-dom";

const UserRole = getUserRole();

function EnrollmentChatByTrainer() {
  const { id: enrollment_id } = useParams();
  return (
    <Box>
      <EnrollmentSupport EnrollmentID={enrollment_id} UserRole={UserRole} />
    </Box>
  );
}

export default EnrollmentChatByTrainer;
