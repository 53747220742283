import { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faCancel } from "@fortawesome/free-solid-svg-icons";
import { createCourse } from "../services/model";
import { getUserID, getUserRole } from "../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BundledEditor from "./BundleEditor";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import data from "../services/data.json";
import CourseOutlineForm from "./CourseSections";
import AdminCourseReview from "./AdminCourseReview";

const user_id = getUserID();
const user_role = getUserRole();

function CourseOutline({ Course, afterCourseUpdate, MediaFiles }) {
  const init_course = {
    course_title: "",
    course_content: "",
    course_price: "",
    skill_level: "",
    course_language: "",
    course_outline: "",
    course_thumb: "",
    course_links: "",
  };

  const navigate = useNavigate();
  const [outline, setOutline] = useState({});
  const [NewCourse, setNewCourse] = useState({ ...init_course });
  const [CourseStatus, setCourseStatus] = useState(null);
  const [TheCourse, setTheCourse] = useState({});

  let ErrorMessage = "";

  const Skills = data.skill_level;
  const Languages = data.languages;

  useEffect(() => {
    const is_editing = !Course ? false : true;
    if (is_editing) {
      const new_course = {
        course_title: Course.title,
        course_content: Course.content,
        course_price: Course.price,
        skill_level: Course.skill_level,
        course_language: Course.language,
        course_thumb: Course.course_thumb,
        course_links: Course.course_links,
      };
      setOutline(Course.outline);
      setNewCourse(new_course);
      setCourseStatus(Course.status);
      setTheCourse(Course);
    }
  }, [Course]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const id = e.target.name;
    let new_course = { ...NewCourse, [id]: value };
    // console.log(new_course);
    setNewCourse(new_course);
  };

  const handleEditorChange = (id, value) => {
    let new_course = { ...NewCourse, [id]: value };
    // console.log(new_course);
    setNewCourse(new_course);
  };

  const handleAddSection = () => {
    const sectionId = `section${Date.now()}`;
    setOutline((prevOutline) => ({
      ...prevOutline,
      [sectionId]: { title: "", subtitles: [] },
    }));
  };

  const handleAddSubtitle = (sectionId) => {
    const subtitleId = `subtitle${Date.now()}`;
    setOutline((prevOutline) => ({
      ...prevOutline,
      [sectionId]: {
        ...prevOutline[sectionId],
        subtitles: [
          ...prevOutline[sectionId].subtitles,
          {
            id: subtitleId,
            text: "",
            contentType: "Video",
            isFree: false,
            mediaFile: "",
          },
        ],
      },
    }));
  };

  const handleSectionTitleChange = (sectionId, value) => {
    setOutline((prevOutline) => ({
      ...prevOutline,
      [sectionId]: {
        ...prevOutline[sectionId],
        title: value,
      },
    }));
  };

  const handleSubtitleChange = (sectionId, subtitleId, key, value) => {
    setOutline((prevOutline) => ({
      ...prevOutline,
      [sectionId]: {
        ...prevOutline[sectionId],
        subtitles: prevOutline[sectionId].subtitles.map((subtitle) =>
          subtitle.id === subtitleId ? { ...subtitle, [key]: value } : subtitle
        ),
      },
    }));
  };

  const handleRemoveSection = (sectionId) => {
    const { [sectionId]: removedSection, ...rest } = outline;
    setOutline(rest);
  };

  const handleRemoveSubtitle = (sectionId, subtitleId) => {
    setOutline((prevOutline) => ({
      ...prevOutline,
      [sectionId]: {
        ...prevOutline[sectionId],
        subtitles: prevOutline[sectionId].subtitles.filter(
          (subtitle) => subtitle.id !== subtitleId
        ),
      },
    }));
  };

  const handleSave = async (course_status) => {
    const total_sections = getTotalSectionInCourse(outline);
    const total_videos = getTotalVideosInCourse(outline);
    const video_duration = getTotalVideoDuration(outline);
    const thumb_data = getCoverThumbData(NewCourse.course_thumb);
    try {
      let data = {
        ...NewCourse,
        course_outline: outline,
        user_id,
        total_sections,
        total_videos,
        thumb_data,
        video_duration,
        course_status,
      };
      // return console.log(data);
      data = !Course ? data : { ...data, id: Course.id };
      const { data: response } = await createCourse(data);
      if (!response.success) {
        return toast.error(response.data.message);
      }
      // console.log(response);
      toast.success(response.data.message);
      afterCourseUpdate(response.data.course);
      redirectToMyCourses();
    } catch (e) {
      // console.log(e);
      toast.error("Error creating course" + e);
    }
  };

  const dataValidated = () => {
    const {
      course_title,
      course_price,
      course_content,
      skill_level,
      course_language,
    } = NewCourse;

    // Check if any of the required fields are null or empty
    if (
      !course_title ||
      !course_content ||
      !course_price ||
      !skill_level ||
      !course_language ||
      !outline ||
      Object.keys(outline).length < 1
    ) {
      ErrorMessage = "Please fill in all required fields.";
      return false;
    }

    // Check if the outline has at least two subtitles
    let subtitleCount = 0;
    for (const sectionKey in outline) {
      const section = outline[sectionKey];
      if (section.subtitles && section.subtitles.length > 0) {
        subtitleCount += section.subtitles.length;
      }
    }

    if (subtitleCount < 2) {
      ErrorMessage = "Please provide at least two subtitles in the outline.";
      return false;
    }

    // Check if the title is at least 3 words long
    const titleWords = course_title.trim().split(/\s+/);
    if (titleWords.length < 3) {
      ErrorMessage = "Title should be at least 3 words long.";
      return false;
    }

    // Check if the content is at least 25 words long
    const contentWords = course_content.trim().split(/\s+/);
    if (contentWords.length < 25) {
      ErrorMessage = "Content should be at least 25 words long.";
      return false;
    }

    ErrorMessage = ""; // Clear any previous error message
    return true;
  };

  const redirectToMyCourses = () => {
    navigate("/trainer-courses");
  };

  function getTotalSectionInCourse(data) {
    let totalSubtitles = 0;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const obj = data[key];
        if (Array.isArray(obj.subtitles)) {
          totalSubtitles += obj.subtitles.length;
        }
      }
    }
    return totalSubtitles;
  }

  function getTotalVideosInCourse(data) {
    let totalVideoSubtitles = 0;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const obj = data[key];
        if (Array.isArray(obj.subtitles)) {
          // eslint-disable-next-line no-loop-func
          obj.subtitles.forEach((subtitle) => {
            if (subtitle.contentType === "Video") {
              totalVideoSubtitles++;
            }
          });
        }
      }
    }
    return totalVideoSubtitles;
  }

  const getTotalVideoDuration = (data) => {
    const video_keys = getVideoKeys(data);
    let totalDuration = 0;

    for (const key of video_keys) {
      const video = MediaFiles.find((m) => m.amazon_data.key === key);
      if (video && video.video_duration) {
        totalDuration += Number(video.video_duration);
      }
    }

    return totalDuration;
  };

  function getVideoKeys(data) {
    let videoKeys = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const obj = data[key];
        if (Array.isArray(obj.subtitles)) {
          // eslint-disable-next-line no-loop-func
          obj.subtitles.forEach((subtitle) => {
            if (subtitle.contentType === "Video") {
              videoKeys = [subtitle.mediaFile, ...videoKeys];
            }
          });
        }
      }
    }
    return videoKeys;
  }

  const getCoverThumbData = (key) => {
    const thumb = MediaFiles.find((m) => m.amazon_data.key === key);
    if (thumb) return thumb;
  };

  return (
    <div>
      {TheCourse.status !== "publish" && (
        <div
          className="alert alert-info"
          role="alert"
          dangerouslySetInnerHTML={{ __html: TheCourse.admin_comments }}
        ></div>
      )}

      <h1>Course Outline</h1>
      <TextField
        margin="normal"
        fullWidth
        id="course_title"
        name="course_title"
        type="text"
        label="Course Title"
        value={NewCourse["course_title"]}
        onChange={(e) => handleInputChange(e)}
      />

      <BundledEditor
        onEditorChange={(content) =>
          handleEditorChange("course_content", content)
        }
        value={NewCourse["course_content"]}
        init={{
          height: 350,
          menubar: false,
          plugins: [
            "advlist",
            "anchor",
            "autolink",
            "help",
            "image",
            "link",
            "lists",
            "searchreplace",
            "table",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />

      <Row className="mt-3 mb-3">
        <Col>
          <TextField
            fullWidth
            id="course_price"
            name="course_price"
            type="number"
            label="Course Price in USD"
            value={NewCourse["course_price"]}
            onChange={(e) => handleInputChange(e)}
          />
          <FormControl fullWidth className="mt-3 mb-3">
            <InputLabel id="skill_level">Skill Level</InputLabel>
            <Select
              id="skill_level"
              name="skill_level"
              label="Skill Level"
              value={NewCourse["skill_level"]}
              onChange={(e) => handleInputChange(e)}
            >
              {Skills.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className="mb-3">
            <InputLabel id="course_language">Language</InputLabel>
            <Select
              id="course_language"
              name="course_language"
              label="Course Language"
              value={NewCourse["course_language"]}
              onChange={(e) => handleInputChange(e)}
            >
              {Languages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="course_thumb">Course Cover Photo</InputLabel>
            <Select
              id="course_thumb"
              name="course_thumb"
              label="Course Thumb"
              value={NewCourse["course_thumb"]}
              onChange={(e) => handleInputChange(e)}
            >
              {MediaFiles.filter((m) => m.type === "image").map((option) => (
                <MenuItem
                  key={option.amazon_data.key}
                  value={option.amazon_data.key}
                >
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <InputLabel id="course_links">Course Links/References</InputLabel>
          <BundledEditor
            onEditorChange={(content) =>
              handleEditorChange("course_links", content)
            }
            value={NewCourse["course_links"]}
            init={{
              height: 250,
              menubar: false,
              plugins: ["link", "wordcount", "anchor", "lists"],
              toolbar: "undo redo | bold link | bullist numlist",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </Col>
      </Row>

      <CourseOutlineForm
        Outline={outline}
        onAddSection={handleAddSection}
        onAddSubtitle={handleAddSubtitle}
        onRemoveSection={handleRemoveSection}
        onSubtitleChange={handleSubtitleChange}
        onRemoveSubtitle={handleRemoveSubtitle}
        onSectionTitleChange={handleSectionTitleChange}
        MediaFiles={MediaFiles}
        onOutlineUpdate={setOutline}
      />
      {CourseStatus === "publish" && (
        <Button
          variant="success"
          onClick={() => handleSave("publish")}
          disabled={!dataValidated()}
        >
          <FontAwesomeIcon icon={faSave} /> Update Course
        </Button>
      )}
      {(CourseStatus === "private" || !CourseStatus) && (
        <>
          <Button
            variant="primary"
            onClick={() => handleSave("private")}
            disabled={!dataValidated()}
          >
            <FontAwesomeIcon icon={faSave} /> Save as Draft
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            variant="success"
            onClick={() => handleSave("draft")}
            disabled={!dataValidated()}
          >
            <FontAwesomeIcon icon={faSave} /> Submit Course
          </Button>
        </>
      )}
      {CourseStatus === "draft" && (
        <p>
          Course cannot be updated while in review. It may take 2-4 days for the
          course to be reviewed.
        </p>
      )}

      <Button variant="danger" className="m-2" onClick={redirectToMyCourses}>
        <FontAwesomeIcon icon={faCancel} /> Cancel
      </Button>
      <Typography variant="body2" color="error">
        {ErrorMessage}
      </Typography>

      {user_role === "admin" && Course && (
        <AdminCourseReview course_id={Course.id} />
      )}
    </div>
  );
}

export default CourseOutline;
