import React, { useState } from "react";
import logo from "./../assets/LoginForm.png";
import SignupImage from "./../assets/REGISTRATIONForm.png";
import { CircularProgress, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createAccount, verifyEmailCode } from "../services/model";
import { NavLink } from "react-router-dom";

const theme = createTheme();

const SignupForm = ({ onSignup }) => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [etTermsAgreed] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signupError, setSignupError] = useState(null);
  const [verifyError, setVerificationError] = useState(null);
  const [working, setWorking] = useState(false);
  const [ALLOK, setALLOK] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setWorking(true);

    if (fullname && email && phone) {
      // All required fields are filled, submit the form
      const user = { fullname, email, phone, password };
      const response = await createAccount(user);
      const { data, success } = response.data;
      setWorking(false);
      if (!success) {
        setSignupError(data.message);
        return;
      }
      // console.log(response);
      setShowVerification(true);
    } else {
      // Some required fields are missing, display an error message or do something else
      alert("Please fill in all required fields and agree to the terms.");
    }
  };

  const handleVerify = async (event) => {
    event.preventDefault();
    const verify = { email, verification_code: verificationCode };
    const response = await verifyEmailCode(verify);
    const { data, success } = response.data;
    if (!success) {
      setVerificationError(data.message);
      return;
    }

    setALLOK(true);
  };

  const isValidEmail = (email) => {
    // regular expression to match email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidWhatsAppNumber = (phoneNumber) => {
    // Remove the plus sign from the phone number
    const cleanedPhoneNumber = phoneNumber.replace("+", "");

    // Regular expression to validate WhatsApp number with area code
    const regex = /^(\+?([0-9]{1,3}))?[-\s]?(\d{2,3}[-\s]?){1,2}\d{2,4}$/;
    return regex.test(cleanedPhoneNumber);
  };

  // Add a function to check if both passwords match
  const passwordsMatch = () => {
    return password === confirmPassword;
  };

  // Disable the signup button unless both passwords match
  const readyToSubmit = () => {
    return (
      passwordsMatch() && isValidEmail(email) && isValidWhatsAppNumber(phone)
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundImage: `url(${SignupImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "inherit",
          }}
        />
        <Grid item xs={10} sm={4} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!showVerification ? (
              <Box
                component="form"
                noValidate={true}
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="fullname"
                  type="text"
                  label="Fullname"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  autoFocus
                  error={!fullname}
                  helperText={
                    !fullname ? "Please enter your fullname" : undefined
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!isValidEmail(email)}
                  helperText={
                    !isValidEmail(email)
                      ? "Please enter a valid email address"
                      : ""
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  type="tel"
                  label="WhatsApp Number (e.g: 92322xxxxxxx)"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={!isValidWhatsAppNumber(phone)}
                  helperText={
                    !isValidWhatsAppNumber
                      ? "Please enter a valid phone number."
                      : ""
                  }
                />
                <hr />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  type="password"
                  placeholder="Re-type Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={!passwordsMatch()} // Show an error if the passwords don't match
                  helperText={!passwordsMatch() && "Passwords do not match"} // Display an error message
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={                     onChange={(e) => setTermsAgreed(e.target.checked)}
                      name="                     color="primary"
                    />
                  }
                  label="I agree to the terms and conditions"
                /> */}
                {signupError && (
                  <Typography variant="subtitle2" color="error">
                    {signupError}
                  </Typography>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={!readyToSubmit()}
                >
                  {working ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Signup"
                  )}
                </Button>
                <Grid container>
                  <Grid item>
                    <NavLink to="/login" variant="body2">
                      Already have an account? Sign in
                    </NavLink>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleVerify}
              >
                <Typography component="h6" variant="h6">
                  We have sent a verification code to your email. Please enter
                  the code below.
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="verificationCode"
                  type="text"
                  placeholder="Verification Code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Verify Account
                </Button>
                {verifyError && (
                  <Typography variant="subtitle2" color="error">
                    {verifyError}
                  </Typography>
                )}

                {ALLOK && (
                  <>
                    <Typography component="h6" variant="h6" color={"green"}>
                      Congratulation! Your account has been verified.
                    </Typography>
                    <NavLink to="/login">Login here</NavLink>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SignupForm;
