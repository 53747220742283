import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CourseOutline from "../../component/CourseForm";

export default function EditCourse({
  onLogout,
  all_courses,
  onCourseUpdate,
  MediaFiles,
}) {
  const { id } = useParams();
  const [Course, setCourse] = useState(null);

  useEffect(() => {
    const found = all_courses.find((course) => course.id === Number(id));
    setCourse(found);
  }, [all_courses, id]);

  // const afterCourseUpdate = (c) => {
  //   onCourseUpdate(c);
  // };
  return (
    <Container>
      <CourseOutline
        Course={Course}
        afterCourseUpdate={onCourseUpdate}
        MediaFiles={MediaFiles}
      />
    </Container>
  );
}
