import { useState, useEffect } from "react";
import { Box, Backdrop, CircularProgress } from "@mui/material";
// import "./App.css";

import SupportThread from "./SupportThread";
import { getEnrollmentSupport } from "../services/model";
import { get_setting } from "../services/helper";

function EnrollmentSupport({ EnrollmentID, UserRole }) {
  // const [pluginSettings, setPluginSettings] = useLocalStorage(
  //   "supportconvo_settings",
  //   {}
  // );

  const [Support, setSupport] = useState(null);
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsWorking(true);
      let { data: support } = await getEnrollmentSupport(EnrollmentID);
      // support = support.data;
      console.log(support);
      setSupport(support);
      setIsWorking(false);
    };
    // setPluginSettings(settings);
    loadData();
  }, [EnrollmentID]);

  const getHeaderNote = () => {
    return UserRole === "customer"
      ? get_setting("header_note_students")
      : get_setting("header_note_trainers");
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="wooconvo-admin-wrapper">
      <div dangerouslySetInnerHTML={{ __html: getHeaderNote() }}></div>
      {Support && <SupportThread Support={Support} UserRole={UserRole} />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWorking}
        onClick={() => setIsWorking(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default EnrollmentSupport;
