import { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import { getUserRole } from "../services/auth";
import plugin_data from "./../services/data.json";
import NavItem from "./NavItem";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const { navbars } = plugin_data;

function NavigationBar({ onLogout, User, UserRole }) {
  const [UserName, setUserName] = useState("");
  useEffect(() => {
    setUserName(User.data.display_name);
  }, [User]);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">NOWA Trainings</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {navbars
              .filter((nav) => nav.access.includes(UserRole))
              .map((nav, index) => (
                <NavItem key={index} nav={nav} />
              ))}
          </Nav>
          <Nav className="nav-right">
            {/* <Nav.Item className="welcome-name m-2">{`Welcome ${UserName}`}</Nav.Item>
            <Button variant="primary" onClick={onLogout} className="nav-link">
              Logout
            </Button> */}
            <NavDropdown
              title={UserName}
              id="basic-nav-dropdown"
              align={"start"}
            >
              <NavDropdown.Item>
                <Link to="/user-settings">Settings</Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#" onClick={onLogout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
