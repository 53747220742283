// import config from "../config";
import httpService from "./http";
import pluginData from "./data.json";

const { siteurl } = pluginData;
const endpoint = `${siteurl}/wp-json/nowa/v1`;

export async function login(user_info) {
  const url = `${endpoint}/login`;
  const { data } = await httpService.post(url, user_info);
  // console.log(user.success);
  const { success, data: response } = data;
  if (success) {
    login_user_locally(response);
    return response;
  }

  throw new Error("Username/password is invalid");
}

export function login_user_locally(user_data) {
  localStorage.setItem("user", JSON.stringify(user_data.user));
  localStorage.setItem("user_roles", JSON.stringify(user_data.user_roles));
}

export function logout() {
  localStorage.removeItem("user");
}

export function getUserID() {
  try {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    return user.ID;
  } catch (ex) {
    return null;
  }
}

export function getCurrentUser() {
  try {
    const user = localStorage.getItem("user");
    return JSON.parse(user);
  } catch (ex) {
    return null;
  }
}

export function getUserRole() {
  try {
    let roles = localStorage.getItem("user_roles");
    roles = JSON.parse(roles);

    if (!roles) return null;
    if (roles.includes("administrator")) return "admin";
    if (roles.includes("trainer")) return "trainer";
    if (roles.includes("student")) return "student";
    if (roles.includes("subscriber")) return "student";
  } catch (ex) {
    return ex;
  }
}

export default {
  login,
  logout,
  getCurrentUser,
};
