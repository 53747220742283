import data from "./../services/data.json";

export const get_setting = (key, defaultValue = "") => {
  var settings = localStorage.getItem("nowa_settings");
  if (!settings) return defaultValue;
  settings = JSON.parse(settings);
  if (!settings[key]) return defaultValue;
  return settings[key];
};

export const wooconvo_makeid = (length = 6) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const _to_options = (options) => {
  return Object.keys(options).map((b) => ({
    value: b,
    label: options[b],
  }));
};

export function get_orderconvo_api_url() {
  return `${data.siteurl}/wp-json/nowa/v1`;
}

export const sanitize_filename = (file_name) => {
  return file_name.replace(/[^a-zA-Z0-9\-\.]/gi, "_").toLowerCase();
};

export function get_job_thumb(job) {
  return (
    <img
      src={process.env.PUBLIC_URL + "/download-icon.png"}
      width="75"
      alt={job.orderID}
    />
  );
}

export function add_type_in_media(data) {
  return data.map((item) => {
    if (!item.amazon_data || !item.amazon_data.location) {
      return item;
    }

    const fileExtension = item.amazon_data.location.split(".").pop();
    let type = "";

    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg"
    ) {
      type = "image";
    } else if (
      fileExtension === "mp4" ||
      fileExtension === "avi" ||
      fileExtension === "mov"
    ) {
      type = "video";
    } else {
      type = "document";
    }

    return {
      ...item,
      type: type,
    };
  });
}

export function get_default_thumb(file_name) {
  const file_type = file_name.split(".").pop();
  return process.env.PUBLIC_URL + `/images/ext/${file_type}.png`;
}
