// import config from "../config";
import httpService from "./http";
import pluginData from "./data.json";
import { getUserID, getUserRole } from "./auth";

const { siteurl } = pluginData;
const endpoint = `${siteurl}/wp-json/nowa/v1`;

export function getAllCourses() {
  const url = `${endpoint}/get-all-courses`;
  return httpService.get(url);
}

export function getTrainerCourses() {
  const user_id = getUserID();
  const user_role = getUserRole();
  const url = `${endpoint}/get-trainer-courses?user_id=${user_id}&user_role=${user_role}`;
  return httpService.get(url);
}

export function getCourseEnrollments(course_id) {
  const url = `${endpoint}/get-course-enrollments?course_id=${course_id}`;
  return httpService.get(url);
}

export function getStudentCourses() {
  const user_id = getUserID();
  const url = `${endpoint}/get-student-enrolled-courses?user_id=${user_id}`;
  return httpService.get(url);
}

export function setActiveSubtitle(course_id, subtitle_id) {
  const data = { course_id, subtitle_id };
  const url = `${endpoint}/set-active-subtitle`;
  return httpService.post(url, data);
}

export function setCompletedSubtitle(course_id, subtitle_id) {
  const data = { course_id, subtitle_id };
  const url = `${endpoint}/set-completed-subtitle`;
  return httpService.post(url, data);
}

export function createCourse(data) {
  const url = `${endpoint}/create-course`;
  return httpService.post(url, data);
}

export function getUserMediaFiles() {
  const user_id = getUserID();
  const url = `${endpoint}/get-user-media?user_id=${user_id}`;
  return httpService.get(url);
}

export function createAccount(data) {
  const url = `${endpoint}/create-account`;
  return httpService.post(url, data);
}

export function addStudent(data) {
  const url = `${endpoint}/add-student`;
  return httpService.post(url, data);
}

export function verifyEmailCode(data) {
  const url = `${endpoint}/verify-email-code`;
  return httpService.post(url, data);
}

export function verifyAccount(data) {
  const url = `${endpoint}/verify-account`;
  return httpService.post(url, data);
}

export function setCourseRating(data) {
  const url = `${endpoint}/set-course-rating`;
  return httpService.post(url, data);
}

export function getEnrollmentSupport(id) {
  const url = `${endpoint}/get-enrollment-support?enrollment_id=${id}`;
  return httpService.get(url);
}

// add message in order
export function addMessage(enrollment_id, message, attachments = []) {
  const user_id = getUserID();
  const user_role = getUserRole();
  const context =
    user_role === "trainer" ? "trainer_dashboard" : "student_dashboard";
  const url = `${endpoint}/send-message`;
  const data = { message, user_id, enrollment_id, attachments, context };
  return httpService.post(url, data);
}

export function resetUnread(enrollment_id, user_type) {
  const data = { enrollment_id, user_type };
  const url = `${endpoint}/reset-unread`;
  return httpService.post(url, data);
}

export function saveSettings(settings) {
  const role = getUserRole();
  const user_id = getUserID();
  const url = `${endpoint}/save-settings`;
  const data = { role, user_id, settings: JSON.stringify(settings) };
  return httpService.post(url, data);
}

export function getSettings(settings) {
  const user_id = getUserID();
  const url = `${endpoint}/get-settings?user_id=${user_id}`;
  return httpService.get(url);
}

export function setCourseReview(data) {
  const url = `${endpoint}/set-course-review`;
  return httpService.post(url, data);
}
