import React, { useState } from "react";
import logo from "./../assets/LoginForm.png";
import SignupImage from "./../assets/REGISTRATIONForm.png";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { addStudent, verifyAccount } from "../services/model";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const theme = createTheme();

const QuickSignup = ({ onSignup }) => {
  const [full_name, setFullname] = useState("");
  const [mobile, setPhone] = useState("");
  const [signupError, setSignupError] = useState(null);
  const [verifyError, setVerificationError] = useState(null);
  const [working, setWorking] = useState(false);
  const [ALLOK, setALLOK] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setWorking(true);

    if (full_name && mobile) {
      // All required fields are filled, submit the form
      const user = { full_name, mobile };
      const response = await addStudent(user);
      const { data, success } = response.data;
      setWorking(false);
      if (!success) {
        setSignupError(data.message);
        return;
      }
      setShowVerification(true);
    } else {
      // Some required fields are missing, display an error message or do something else
      alert("Please fill in all required fields and agree to the terms.");
    }
  };

  const isValidWhatsAppNumber = (mobileNumber) => {
    // Remove the plus sign from the mobile number
    const cleanedPhoneNumber = mobileNumber.replace("+", "");

    // Regular expression to validate WhatsApp number with area code
    const regex = /^(\+?([0-9]{1,3}))?[-\s]?(\d{2,3}[-\s]?){1,2}\d{2,4}$/;
    return regex.test(cleanedPhoneNumber);
  };

  // Disable the signup button unless both passwords match
  const readyToSubmit = () => {
    return isValidWhatsAppNumber(mobile);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundImage: `url(${SignupImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "inherit",
          }}
        />
        <Grid item xs={10} sm={4} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!showVerification ? (
              <Box
                component="form"
                noValidate={true}
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                <h2>Register Student Account</h2>

                <hr />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="full_name"
                  type="text"
                  label="Full Name"
                  value={full_name}
                  onChange={(e) => setFullname(e.target.value)}
                  autoFocus
                  error={!full_name}
                  helperText={
                    !full_name ? "Please enter your full_name" : undefined
                  }
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="mobile"
                  type="tel"
                  label="WhatsApp Number (e.g: 92322xxxxxxx)"
                  value={mobile}
                  onChange={(e) => setPhone(e.target.value)}
                  error={!isValidWhatsAppNumber(mobile)}
                  helperText={
                    !isValidWhatsAppNumber
                      ? "Please enter a valid mobile number."
                      : ""
                  }
                />
                <hr />

                {signupError && (
                  <Typography variant="subtitle2" color="error">
                    {signupError}
                  </Typography>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={!readyToSubmit()}
                >
                  {working ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Signup"
                  )}
                </Button>
                <Grid container>
                  <Grid item>
                    <NavLink to="/login" variant="body2">
                      Already have an account? Sign in
                    </NavLink>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                component="div"
                sx={{
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography component="h3" variant="h3" align="center">
                  Congratulations!
                </Typography>
                <hr />
                <Typography component="h3" variant="h4" mt={2} mb={1}>
                  Please check your WhatsApp for the next step.
                </Typography>
                <Box
                  sx={{ mt: 2, backgroundColor: "#f8f8f8", padding: "10px" }}
                >
                  <Typography component="p" variant="body2" align="center">
                    If you didn't receive the WhatsApp message, please contact
                    us via <strong>923087203976</strong>.
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default QuickSignup;
