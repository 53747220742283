import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { setCourseReview } from "../services/model";
import { toast } from "react-toastify";

const AdminCourseReview = ({ course_id }) => {
  const [comment, setComment] = useState("");
  const [courseCode, setcourseCode] = useState("");

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCourseReview = async (status) => {
    const data = { status, comment, course_id, course_code: courseCode };
    const { data: response } = await setCourseReview(data);
    if (response.success) {
      return toast.success(response.data.message);
    } else {
      return toast.error(response.data.message);
    }
  };

  return (
    <Container className="m-3">
      <h2>Course Review</h2>
      <Form>
        <Form.Group controlId="comment">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            onChange={handleCommentChange}
          />
        </Form.Group>
        <Form.Group controlId="courseid">
          <Form.Label>Course ID</Form.Label>
          <Form.Control
            type="text"
            value={courseCode}
            onChange={(e) => setcourseCode(e.target.value)}
          />
        </Form.Group>
        <Button variant="success" onClick={() => handleCourseReview("publish")}>
          Approve Course
        </Button>{" "}
        <Button variant="danger" onClick={() => handleCourseReview("private")}>
          Reject Course
        </Button>
      </Form>
    </Container>
  );
};

export default AdminCourseReview;
