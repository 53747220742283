import { useEffect, useState } from "react";
import { Table, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { LinearProgress, Typography } from "@mui/material";

function StudentCourses({ all_courses }) {
  const [Courses, setCourses] = useState([...all_courses]);
  useEffect(() => {
    console.log(all_courses);
    setCourses(all_courses);
  }, [all_courses]);

  const renderProgress = (course) => {
    const { completed_subtitles, course_data } = course;
    const totalSections = course_data.total_sections;
    const completedSections = completed_subtitles.length;

    // Calculate the progress percentage
    const progress = (completedSections / totalSections) * 100;

    return (
      <>
        <LinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" align="center">
          {`${completedSections}/${totalSections} Sections Completed`}
        </Typography>
      </>
    );
  };

  return (
    <Container fluid>
      <h2>My Courses</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Progress</th>
          </tr>
        </thead>
        <tbody>
          {Courses.map((course) => (
            <tr key={course.id}>
              <td>{course.id}</td>
              <td>
                <Link to={`/outline/${course.id}`}>{course.title}</Link>
              </td>
              <td>{renderProgress(course)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default StudentCourses;
