import React from "react";
import ReactPlayer from "react-player";
import { FaFastForward, FaBackward } from "react-icons/fa";

export function VideoPlayerContent({
  mediaFile,
  video_cdn,
  onProgress,
  onEnded,
}) {
  const playerRef = React.useRef(null);

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handlePrevious = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  return (
    <>
      <ReactPlayer
        ref={playerRef}
        url={`${video_cdn}/${mediaFile}`}
        controls
        playing
        width="100%"
        height="auto"
        config={{
          file: {
            attributes: {
              controlsList: "nodownload", // Disable download option
            },
          },
        }}
        volume={0.5}
        progressInterval={1000} // Update progress every 1 second
        onProgress={onProgress} // Handle progress updates
        onEnded={onEnded} // Handle end of video
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          <FaBackward size={24} onClick={handlePrevious} /> 10 sec
        </span>
        <span>
          10 sec <FaFastForward size={24} onClick={handleFastForward} />
        </span>
      </div>
    </>
  );
}
