import { useEffect, useState } from "react";
import { Table, Button, Container, Badge } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { PageHeader } from "../../component/PageHeader";
import { getCourseEnrollments } from "../../services/model";
import {
  Backdrop,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import data from "../../services/data.json";

function CourseEnrollments() {
  const { id: course_id } = useParams();
  const [Enrollments, setEnrollments] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [Title, setTitle] = useState("");

  useEffect(() => {
    const loadData = async () => {
      setIsWorking(true);
      let { data: enrollments } = await getCourseEnrollments(course_id);
      setTitle(enrollments[0]["title"]);
      setEnrollments(enrollments);
      setIsWorking(false);
    };
    loadData();
  }, [course_id]);

  function calculateNetAmount(amount) {
    // Validate the inputs
    const commissionPercentage = data.commission;
    amount = Number(amount);
    if (
      typeof amount !== "number" ||
      typeof commissionPercentage !== "number"
    ) {
      return NaN;
    }

    // Calculate the commission amount
    var commission = (amount * commissionPercentage) / 100;

    // Calculate the net amount after deducting the commission
    var netAmount = amount - commission;

    return netAmount;
  }

  const renderProgress = (course) => {
    const { completed_subtitles, course_data } = course;
    const totalSections = course_data.total_sections;
    const completedSections = completed_subtitles.length;

    // Calculate the progress percentage
    const progress = (completedSections / totalSections) * 100;

    return (
      <>
        <LinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" align="center">
          {`${completedSections}/${totalSections} Sections Completed`}
        </Typography>
      </>
    );
  };

  return (
    <Container fluid>
      {Title && <PageHeader Title={Title} ButtonLabel="" ButtonPath="" />}

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>DateEnrollment</th>
            <th>Student</th>
            <th>Progress</th>
            <th>Fee</th>
            <th>After Commission (15%)</th>
            <th>Chats/Support</th>
          </tr>
        </thead>
        <tbody>
          {Enrollments.map((enrollment) => (
            <tr key={enrollment.id}>
              <td>{enrollment.id}</td>
              <td>{enrollment.date_enrolled}</td>
              <td
                dangerouslySetInnerHTML={{
                  __html: `${enrollment.student_name}<br>(${enrollment.student_email})`,
                }}
              ></td>

              <td>{renderProgress(enrollment)}</td>
              <td>{enrollment.course_data.price}</td>
              <td>{calculateNetAmount(enrollment.course_data.price)}</td>
              <td>
                <Link to={`/enrollments/${enrollment.id}/chat/`}>
                  <Button variant="primary">
                    <FontAwesomeIcon icon={faMessage} />
                    <Badge className="m-1" bg="info">
                      {enrollment.unread_trainer}
                    </Badge>
                    <span className="visually-hidden">unread messages</span>
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWorking}
        onClick={() => setIsWorking(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default CourseEnrollments;
