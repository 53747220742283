import React, { useEffect, useState } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import data from "../../services/data.json";
import { VideoPlayerContent } from "../../component/VideoPlayerContent";

const { video_cdn } = data;

const EnrolledOutlinePreview = ({ all_courses }) => {
  const { id } = useParams();
  const [CourseOutline, setCourseOutline] = useState({});
  const [selectedSubtitle, setSelectedSubtitle] = useState({});

  useEffect(() => {
    const loadData = async () => {
      const found = all_courses.find((course) => course.id === Number(id));
      // console.log(found);
      if (found) {
        const outline = found.outline;
        setCourseOutline(outline);
        const firstSubtitle = Object.keys(outline)[0];

        let selected_subtitle = outline[firstSubtitle].subtitles[0];

        setSelectedSubtitle(selected_subtitle);
      }
    };

    loadData();
  }, [all_courses, id]);

  return (
    <Container fluid>
      <div className="enrolled-outline mt-5">
        <div className="enrolled-outline__outline">
          {Object.entries(CourseOutline).map(([sectionId, sectionData]) => (
            <div key={sectionId} className="mb-2">
              <ListGroup as="ul">
                <ListGroup.Item as="li" variant="primary">
                  {sectionData.title}
                </ListGroup.Item>
                {sectionData.subtitles.map((subtitle) => (
                  <ListGroup.Item
                    key={subtitle.id}
                    action
                    active={selectedSubtitle.id === subtitle.id}
                    onClick={() => setSelectedSubtitle(subtitle)}
                  >
                    {subtitle.text}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          ))}
        </div>

        <div className="enrolled-outline__content">
          {selectedSubtitle && (
            <>
              {selectedSubtitle.contentType === "Video" ? (
                <VideoPlayerContent
                  mediaFile={selectedSubtitle.mediaFile}
                  video_cdn={video_cdn}
                />
              ) : (
                <iframe
                  src={`${video_cdn}/${selectedSubtitle.mediaFile}`}
                  title={selectedSubtitle.text}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default EnrolledOutlinePreview;
