import { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

import ReplyMsg from "./ReplyMsg";
import MessagesBody from "./Messages";
import "./thread.css";
import NavBar from "./NavBar";
import { addMessage, resetUnread } from "../services/model";
import { get_orderconvo_api_url } from "../services/helper";
const api_url = get_orderconvo_api_url();

export default function SupportThread({ Support, UserRole }) {
  // console.log(Support);
  const [Thread, setThread] = useState([]);
  const [showMore, setshowMore] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [FilterThread, setFilterThread] = useState([]);

  const context =
    UserRole === "student" ? "student_dashboard" : "trainer_dashboard";
  const { enrollment_id, is_completed } = Support;
  console.log(UserRole);

  useEffect(() => {
    const { enrollment_id, thread, unread_student, unread_trainer } = Support;
    setFilterThread(thread);
    setThread(thread);

    const markSupportAsRead = async () => {
      const unread_count =
        UserRole === "student" ? unread_student : unread_trainer;
      if (Number(unread_count) > 0) {
        // console.log(UserRole);
        await resetUnread(enrollment_id, UserRole);
      }
    };

    markSupportAsRead();
  }, [Support, UserRole]);

  const handleReplySend = async (reply_text, files = []) => {
    setIsWorking(true);
    var attachments = [];
    console.log(files);
    attachments = await handleFileUpload(files);

    try {
      const { data: response } = await addMessage(
        enrollment_id,
        reply_text,
        attachments
      );
      const { success, data: support } = response;
      const { thread } = support;
      setIsWorking(false);
      if (success) {
        setThread(thread);
        setFilterThread(thread);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // upload to server
  const handleFileUpload = (files) => {
    var promises = [];
    files.forEach(async (file) => {
      const p = new Promise(async (resolve, reject) => {
        const resp = await uploadFile(file);
        const { data: attachment } = await resp.json();
        resolve(attachment);
      });
      promises.push(p);
    });
    return Promise.all(promises);
  };

  // upload to local server
  const uploadFile = (file) => {
    // console.log(file);
    const url = `${api_url}/upload-file`;
    const data = new FormData();
    data.append("file", file);
    data.append("enrollment_id", enrollment_id);
    return fetch(url, { method: "POST", body: data });
  };

  const handleSearch = (str) => {
    let thread = [...Thread];
    thread = thread.filter((r) => matchSearch(str, r.message));
    console.log(thread);
    setFilterThread(thread);
  };

  const matchSearch = (text, testwith) => {
    const regex = new RegExp("(?:^|\\s)" + text, "gi");
    return regex.test(testwith);
  };

  const handleDownload = async (file) => {
    const { filename } = file;

    const download_url = `${api_url}/download-file?filename=${filename}&enrollment_id=${enrollment_id}`;
    window.open(download_url);
  };

  return (
    <>
      <NavBar
        SupportID={enrollment_id}
        Context={context}
        onCollapsed={() => setshowMore(!showMore)}
        showMore={showMore}
        onSearchThread={handleSearch}
      />
      <MessagesBody
        Thread={FilterThread}
        showMore={showMore}
        onDownload={handleDownload}
      />

      {/* <Divider variant="inset" component="h2" sx={{ height: 10 }} /> */}

      {/* Reply to --- */}
      {!is_completed && <ReplyMsg onReplySend={handleReplySend} />}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWorking}
        onClick={() => setIsWorking(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
