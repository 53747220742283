import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ nav }) => {
  const { title, slug } = nav;

  const renderLink = () => {
    if (slug) {
      return (
        <Link to={slug} className="nav-link">
          {title}
        </Link>
      );
    } else {
      return (
        <a
          href={nav.link}
          rel="noreferrer"
          className="nav-link"
          target="_blank"
        >
          {nav.title}
        </a>
      );
    }
  };

  return <li className="nav-item">{renderLink()}</li>;
};

export default NavItem;
