import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Row, Col } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function CourseOutlineForm({
  Outline,
  onAddSection,
  onSectionTitleChange,
  onRemoveSection,
  onAddSubtitle,
  onSubtitleChange,
  onRemoveSubtitle,
  MediaFiles,
  onOutlineUpdate,
}) {
  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was not dropped in a valid location

    const items = Array.from(Object.keys(Outline));
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items);

    const updatedOutline = {};
    items.forEach((itemId, index) => {
      console.log(itemId, Outline[itemId]);
      updatedOutline[itemId] = Outline[itemId];
    });

    onOutlineUpdate(updatedOutline);

    console.log(updatedOutline);

    // Update the state or perform any necessary actions with the updated Outline
    // Example: updateOutline(updatedOutline);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="sections">
        {(provided) => (
          <div
            className="nowa-sections"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {Object.keys(Outline).map((sectionId, index) => {
              const section = Outline[sectionId];
              return (
                <Draggable
                  key={sectionId}
                  draggableId={sectionId}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className={`nowa-section ${sectionId}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="my-4 d-flex align-items-center">
                        <Form.Control
                          type="text"
                          placeholder="Section Title"
                          value={section.title}
                          onChange={(e) =>
                            onSectionTitleChange(sectionId, e.target.value)
                          }
                        />
                        <Button
                          variant="danger"
                          className="m-2"
                          onClick={() => onRemoveSection(sectionId)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button
                          variant="success"
                          onClick={() => onAddSubtitle(sectionId)}
                          className="ml-2"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </div>
                      {section.subtitles &&
                        section.subtitles.map((subtitle) => (
                          <Row key={subtitle.id} className="mb-2">
                            <Col className="col-4">
                              <Form.Control
                                type="text"
                                placeholder="Subtitle Text"
                                value={subtitle.text}
                                onChange={(e) =>
                                  onSubtitleChange(
                                    sectionId,
                                    subtitle.id,
                                    "text",
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className="form-select"
                                as="select"
                                value={subtitle.contentType}
                                onChange={(e) =>
                                  onSubtitleChange(
                                    sectionId,
                                    subtitle.id,
                                    "contentType",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="Video">Video</option>
                                <option value="PDF">PDF</option>
                              </Form.Control>
                            </Col>
                            <Col>
                              <Form.Control
                                className="form-select"
                                as="select"
                                value={subtitle.mediaFile}
                                onChange={(e) =>
                                  onSubtitleChange(
                                    sectionId,
                                    subtitle.id,
                                    "mediaFile",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Media</option>
                                {MediaFiles.map((option, index) => (
                                  <option
                                    key={index}
                                    value={option.amazon_data.key}
                                  >
                                    {option.title}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                            <Col>
                              <Form.Check
                                type="checkbox"
                                label="Is Free"
                                checked={subtitle.isFree}
                                onChange={(e) =>
                                  onSubtitleChange(
                                    sectionId,
                                    subtitle.id,
                                    "isFree",
                                    e.target.checked
                                  )
                                }
                              />
                            </Col>
                            <Col xs="auto">
                              <Button
                                variant="danger"
                                onClick={() =>
                                  onRemoveSubtitle(sectionId, subtitle.id)
                                }
                                className="ml-2"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className="text-center">
        <Button variant="primary" onClick={onAddSection}>
          <FontAwesomeIcon icon={faPlus} /> Add Section
        </Button>
      </div>
    </DragDropContext>
  );
}

export default CourseOutlineForm;
